
import first from "../../assets/onboarding-images/1.png"
import second from "../../assets/onboarding-images/2.png"
import third from "../../assets/onboarding-images/3.png"
import fourth from "../../assets/onboarding-images/4.png"
import fifthCommon from "../../assets/onboarding-images/5.png"
import visualMap from "../../assets/onboarding-images/5.1.png"
import capture from "../../assets/onboarding-images/5.2.png"
import getHelp from "../../assets/onboarding-images/getHelp.png"
import helpByBearn from "../../assets/bernImages/help-by-bearn.jpg"
import { companyBucket } from "../../Utils/tpscanStyle"


export const data = [
    { image: first, title: 'Grab your phone', description: 'Get ready to snap some pictures' },
    { image: second, title: 'Stand in front of the mirror', description: '' },
    { image: third, title: 'Proper Light is key!', description: 'Ensure your space is well-lit' },
    { image: fourth, title: 'Have the front camera facing the mirror', description: "Hold your phone's back camera towards your teeth, look in the mirror" },
    { image: fifthCommon, inner: visualMap, title: `Let ${companyBucket === "done" ? "Dentistry.One" : "the App"} create a visual map `, description: 'Align your teeth within the template for a perfect shot' },
    { image: fifthCommon, inner: capture, title: 'Tap anywhere to capture your smile', description: '' },
    // { image: getHelp, title: 'Get set to flaunt your best smile!', description: 'Pose comfortably for precise photos of your teeth' },
    // { image: getHelp, video: true, title: 'Quick Scan Video', description: 'Watch this quick demo to learn how to take an accurate scan of your teeth' },
    { image: helpByBearn, title: 'Struggling to take clear dental photos?', description: 'If you have small hands or a large phone, ask someone else to help you take the photos' },




]