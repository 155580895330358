import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    reportsData: [],
    fetched: false,
}

const reportsSlice = createSlice({
    name: 'reports',
    initialState,
    reducers: {
        addReports: (state, action) => {
            state.reportsData = action.payload
            state.fetched = true
        },
        clearReports: () => {
            return initialState
        }
    }
})


export default reportsSlice.reducer
export const { addReports, clearReports } = reportsSlice.actions