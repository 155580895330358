
export const company_style_details = JSON.parse(
    localStorage.getItem("company_style_details")
  );
  
  export const buttonStyle = {
    backgroundColor: company_style_details
      ? `${company_style_details.button_background_color}`
      : "#000000",
  };

  export const fontWeight = {
    fontWeight: company_style_details
      ? `${company_style_details.onboarding_text_font_weight}`
      : 900,
  };
  
  export const fontStyle = {
    fontFamily: company_style_details
      ? `${company_style_details.onboarding_text_font_style}`
      : "Raleway",
  };

export const companyBucket=localStorage.getItem("companyBucket")?JSON.parse( localStorage.getItem("companyBucket")).companyBucket: window.location.pathname.split("/")[2]