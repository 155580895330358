import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    images: [],
    tpScanS3Folder: '',
    tpCompanyBucket:''
}

const tpScanSlice = createSlice({
    name: 'tpScan',
    initialState,
    reducers: {
        clearImages: () => {
            return initialState
        },
        addImages: (state, action) => {
            state.images = [...state.images, action.payload]
        },
        createTpScanS3Folder: (state,action) => {
            state.tpScanS3Folder = action.payload.uid;
            state.tpCompanyBucket=action.payload.tpCompanyBucket
        },
        clearTpScanfolder: (state) => {
            state.tpScanS3Folder = ''
        }
    }

})

export default tpScanSlice.reducer
export const { clearImages, addImages, createTpScanS3Folder, clearTpScanfolder } = tpScanSlice.actions