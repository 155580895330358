import { configureStore } from "@reduxjs/toolkit";
import fullScanReducer from "./fullScan";
import quickScanReducer from "./quickScan";
import reportsReducer from "./reports"
import tpScanReducer from "./tpScan"
import teethHealthReducer from "./teethHealth"
import questionnaireReducer from "./questionnaire"
import profileLogoReducer from "./profile"

export const store = configureStore({
    reducer: {
        fullScan: fullScanReducer,
        quickScan: quickScanReducer,
        reports: reportsReducer,
        tpScan:tpScanReducer,
        teethHealth : teethHealthReducer,
        questionnaire : questionnaireReducer,
        profileLogo :profileLogoReducer
    }
})

export default store