import react, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import successImage from "../../assets/onboarding-images/success.png";
import { useDispatch, useSelector } from "react-redux";
import { createFullScanS3Folder } from "../../Store/fullScan";
import stars from "../../assets/illustrations/backgorund-stars.png";
import teeth from "../../assets/illustrations/thanksTeeth.png";
import toothlensLogo from "../../assets/illustrations/Toothlens_logo.png";
import { companyBucket } from "../../Utils/tpscanStyle";
import close from "../../assets/Icons/close.png"
const SuccessScreen = ({ isFullScan }) => {
  const { dentalOfficeName } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { fullScanS3Folder } = useSelector((state) => state.fullScan);

  useEffect(() => {
    if (localStorage.getItem("usertpi")) {
      navigate(`/report`);
    }
  }, []);


  const TpSuccess = () => {
    return (
      <div>
        <div className="h-full text-center flex flex-col justify-center items-center px-3">
          <img src={stars} alt="stars" className="absolute top-0 w-full" />
          <img src={teeth} alt="teeth" className=" h-40  saturate-50 mb-6" />
          <p className=" font-medium text-2xl mb-3">Thank you!</p>
          <p className=" text-sm opacity-60 px-5">
            Your scan is complete. Keep smiling and see you at your next scan!
          </p>
          <p className="flex flex-row justify-center absolute bottom-8">
            <span className="opacity-60"> Powered by </span>
            <img
              src={toothlensLogo}
              alt="welcome teeth"
              className="h-6 ml-2"
            />
          </p>
        </div>

      </div>
    );
  };


  return (
    <>{localStorage.getItem("usertpi") ? <TpSuccess /> : <p></p>}</>
  );
};

export default SuccessScreen;
