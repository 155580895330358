
export const formattedDate = (value) => {
    if (value) {
        const date = new Date(value)
        const day = (date.getDate() < 10 ? '0' : '') + date.getDate();
        const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'Oct0ber', 'November', 'December'];
        const month = months[date.getMonth()];
        const year = String(date.getFullYear());
        return `${month} ${day}, ${year}`;
    } else {
        return 'Recent'
    }
}
export const formatDateToMMDDYY = () => {
    const date = new Date();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const year = String(date.getFullYear());

    return `${month}/${day}/${year}`;
  };
export const FormikDate = (value) => {
    const date = new Date(value)
    const year = date.getFullYear();
    let month = (date.getMonth() + 1).toString(); // Months are zero-indexed
    let day = date.getDate().toString();

    // Pad single digit months and days with a leading zero
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return `${year}-${month}-${day}`;
}