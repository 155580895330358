import React from "react";
import Speedometer from "./speedometer";


const BookAppointment = () => {
  return (
    <div className="border rounded mx-6 mt-4 p-3">
     <Speedometer/>
    </div>

  );
};

export default BookAppointment;
