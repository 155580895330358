import { API_HOST } from "../BaseUrl"
import client, { headers } from "./config"

export const imageUpload = ({ formData, folderName }) => {
    const updatedHeaders = {
        ...headers,
        'Content-Type': 'multipart/form-data',
        folderName
    }
    return new Promise(async (resolve, reject) => {


        try {
            if(localStorage.getItem("usertpi")){
                updatedHeaders.dentalOfficeName=JSON.parse(localStorage.getItem("companyBucket")).companyBucket
                updatedHeaders.folderName=JSON.parse(localStorage.getItem("usertpi")).usertpi

              const response=  await client.post(API_HOST + `report/image/annotator/${JSON.parse(localStorage.getItem("companyBucket")).companyBucket}/${JSON.parse(localStorage.getItem("usertpi")).usertpi}`,formData, { headers: updatedHeaders })
                    if (response.status === 200) {
                    resolve(response);
                  }
                  reject(response);
            
            }else{
                const response = await client.post(API_HOST + `report/image`, formData, { headers: updatedHeaders })
                if (response.status === 200) {
                    resolve(response)
                }
                reject(response.data)
            }
           
        } catch (error) {
            reject(error)
        }
    })
}

export const submitScan = (data) => {
    const updatedHeader = {
        ...headers,
        userId: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).user._id : "",
        issmartCheck: localStorage.getItem('user')?true:false,
        uid:JSON.parse(localStorage.getItem('usertpi')).usertpi,
        companyName:localStorage.getItem('user')? "toothlens":JSON.parse(localStorage.getItem('companyBucket')).companyBucket,
    }
    return new Promise(async (resolve, reject) => {
        try {
            const response = await client.post(API_HOST + `report/post-report-images`, data, { headers: updatedHeader })
            if (response.status === 200) {
                resolve(response)
            }
            reject(response)

        } catch (error) {
            reject(error)
        }
    })
}

export const createScan = (data) => {
    const updatedHeaders = { ...headers, Authorization: localStorage.getItem("authToken") }

    if(localStorage.getItem('user')){
        return new Promise(async (resolve, reject) => {
            try {
                const response = await client.post(API_HOST + 'smartcheck/report', data, { headers: updatedHeaders })
                if (response.status === 200) {
                    resolve(response)
                }
                reject(response)
    
            } catch (error) {
                reject(error)
            }
        })
    }else{
        return new Promise(async(resolve, reject) => {
            data.tpid=JSON.parse( localStorage.getItem('usertpi')).usertpi
        const reponse = await
            client.post(API_HOST + `tpscan/report/${JSON.parse(localStorage.getItem("companyBucket")).companyBucket}`, data, { headers: headers })
              .then((response) => {
                if (response.status === 200) {
                  resolve(response.data.data);
                }
                reject(response);
              })
              .catch((error) => {
                return error;
              });
          });
    }
   
}

export const getTpStatus = (reportId) => {
    return new Promise(async(resolve, reject) => {
    const response= await client.get(API_HOST + `tpstatus/${reportId}`)
    if (response.status === 200) {
        resolve(response);
      }
      reject(response);
    });
  };
