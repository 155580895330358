import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    selectedOptions: [],
    reason: ''
}

const questionnaireSlice = createSlice({
    name: "questionnaire",
    initialState,
    reducers: {
        addOptions: (state, action) => {
            state.selectedOptions = action.payload
        },

        addReason: (state, action) => {
            state.reason = action.payload
        },

        clearQuestionnaire: () => {
            return initialState
        }
    }
})


export default questionnaireSlice.reducer
export const { addOptions, addReason, clearQuestionnaire } = questionnaireSlice.actions