import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Camera, { FACING_MODES, IMAGE_TYPES } from "react-html5-camera-photo";
import 'react-html5-camera-photo/build/css/index.css';
import back from "../../assets/Icons/Back-white.png"
import demoIcon from "../../assets/Icons/image-reference.png"
import teethListIcon from "../../assets/Icons/teeth-list-icon.png"
import flashOn from "../../assets/Icons/flash-on.png"
import flashOff from "../../assets/Icons/flash-off.png"
import DemoScreens from './demoScreens';
import Preview from './preview';
import "./index.css"
import steps from './steps';
import PermissionPopup from './cameraPermissionPopup';
import { GetBrowserDetails } from '../../Utils/deviceDetails';
import { GetMobileOperatingSystem } from '../../Utils/getOS';
import { fontStyle,fontWeight,buttonStyle, companyBucket } from "../../Utils/tpscanStyle"

const Shoot = (props) => {
    const { dentalOfficeName } = useParams();
    const { activeImageSide, setToothPicker, quickScan } = props
    const navigate = useNavigate()
    const [isDemo, setDemo] = useState(true)
    const [isCamera, setCamera] = useState(true)
    const [capturedImage, setCapturedImage] = useState()
    const [flashLight, setFlashLight] = useState(false)
    const [mediaStream, setStream] = useState(null)
    const [errorType, setErrorType] = useState({ isError: false, type: "ios" })
    const OS = GetMobileOperatingSystem()   // operating system

    useEffect(() => {
        // Clean-up function to reset flashlight state when component unmounts or user navigates away
        return () => {
            if (mediaStream && flashLight) {
                setFlashLight(false)
                torchMechanism(mediaStream, false); // Turn off flashlight when component unmounts
            }
        };
    }, [mediaStream]);



    const handleTakePhoto = (dataUri) => {
        setCapturedImage(dataUri)
        flashLight && torchMechanism(mediaStream, false)
        setTimeout(() => {
            setCamera(false)
        }, 400)
    }

    const toggleFlash = () => {

        if (flashLight) {
            setFlashLight(false);
            torchMechanism(mediaStream, false);
        } else {
            setFlashLight(true);
            torchMechanism(mediaStream, true);
        }
    }



    const torchMechanism = (stream, flashLight) => {
        if (stream) {
            const track = stream.getVideoTracks()[0];
            if (track) {
                track.applyConstraints({
                    advanced: [{ torch: flashLight }]
                }).then(() => {
                    console.log("Torch constraint applied successfully");
                }).catch((e) => {
                    console.log("Failed to apply torch constraint:");
                });
            } else {
                console.warn("No video track found");
            }

        }


    };



    const cameraStarted = (stream) => {
        setStream(stream)
        torchMechanism(stream, flashLight)
        setErrorType({ isError: false, type: "ios" })
    }

    const checkError = () => {
        const browser = GetBrowserDetails();

        if (browser.name === "Mobile Chrome") {
            navigator.permissions.query({ name: 'camera' }).then((res) => {
                if (res.state === "denied" && OS === 'iOS') {
                    setErrorType({ isError: true, type: "ios" })
                } else {
                    setErrorType({ isError: true, type: "android" })
                }
            }).catch((error) => {
                setErrorType({ isError: true, type: "ios" })
            })

        } else {
            setErrorType({ isError: true, type: "ios" })
        }

    }

    const OvuleShape = () => {
        return (
            <div className='ovule-container w-full flex flex-col justify-center items-center '>
                <div className={` ovule ovule-${activeImageSide}`}> </div>
                <p className={` text-center text-base text-white my-3`}style={{...fontWeight,...fontStyle}}>{steps[activeImageSide].title}</p>
            </div>
        )
    }


    const TopLayer = () => {
        return (
            <div className='camera-top-layer-2 h-full absolute top-0 text-white w-full'>
                <div className='flex justify-between items-center h-13 px-2'>
                    <button type='button' onClick={() => { quickScan ? navigate(`/${dentalOfficeName}/dashboard`) : setToothPicker(true) }}> <img src={back} alt="back" className="w-8 h-8" /> </button>
                    <div className='pt-2'>
                        <button type='button' onClick={() => { setDemo(true) }} > <img src={demoIcon} alt="back" className="w-12 h-12 " /> </button>
                        {!quickScan && <button type='button' onClick={() => { setToothPicker(true) }}> <img src={teethListIcon} alt="back" className="w-12 h-12 mx-2" /> </button>}
                    </div>
                </div>
                <p className='text-base mt-16 text-center'style={{...fontWeight,...fontStyle, }}>  {isCamera ? "Tap anywhere to capture" : `Preview of the ${steps[activeImageSide].title}`}</p>
            </div>
        )
    }


    return (
        <>
            {isDemo ? <DemoScreens activeImageSide={activeImageSide} setToothPicker={setToothPicker} setDemo={setDemo} setCamera={setCamera} quickScan={quickScan} /> :
                <div className='h-screen'>
                    {errorType.isError && <PermissionPopup info={errorType} setErrorType={setErrorType} />}
                    {
                        isCamera ?
                            <>
                                <Camera
                                    onTakePhotoAnimationDone={(dataUri) => { handleTakePhoto(dataUri) }}
                                    idealFacingMode={FACING_MODES.ENVIRONMENT}
                                    isFullscreen={true}
                                    isMaxResolution={true}
                                    imageType={IMAGE_TYPES.JPG}
                                    isImageMirror={false}
                                    imageCompression={0.6}
                                    onCameraStart={cameraStarted}
                                    onCameraError={checkError}
                                    className="WebCam"
                                />
                                {OS !== "iOS" &&
                                    <button
                                        type="button"
                                        onClick={toggleFlash}
                                        className='flash-container absolute bottom-6 right-4 shadow-custom shadow-zinc-600 bg-black font-medium font-base rounded-full px-3 py-2 h-12 w-12'
                                    >
                                        <img src={flashLight ? flashOff : flashOn} className='h-5' alt="flash" />
                                    </button>}
                            </> : <Preview image={capturedImage} setCamera={setCamera} setToothPicker={setToothPicker} activeImageSide={activeImageSide} setDemo={setDemo} quickScan={quickScan} />
                    }
                    <OvuleShape />
                    <TopLayer />

                </div >
            }
        </>
    )
}

export default Shoot